/*
Theme Name: Globals - Material and Bootstrap HTML template
Module : Health/Medical
Description: index slider styles are defined in this class
Author: Jyostna
Author URI: http://themeforest.net/user/jyostna
Version: 1.0 
-------------------------------------------- */


/*
TABLE OF CONTENT
-------------------------------------------------
1- REVOLUTION SLIDER STYLES
2- SELECT2 CUSTOM STYLES
---------------------------------------------- */


/*========================== REVOLUTION SLIDER STYLES =======================*/

.slide-r-i {
    font-size: 14px;
    position: relative;
    top: 1px;
}

.grey_regular_18 {
    font-size: 18px !important;
}

.largeredbtn {
    background: #252525 !important;
    border-radius: 28px !important;
    font-weight: normal;
    font-family: 'roboto', sans-serif;
    padding: 8% 19%!important;
    color: #ffffff !important;
}

.largeredbtn:hover {
    background: #00bff3 !important;
}

.slide-doller-img {
    width: 80% !important;
    height: 80% !important;
}


/*========================== REVOLUTION SLIDER STYLES END =======================*/


/*========================== SELECT2 CUSTOM STYLES =======================*/

.select2-container--default .select2-selection--single {
    border: 0;
}

.select2-container--default .select2-results > .select2-results__options {
    overflow-y: hidden;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    display: none;
}

.select2-container--default .select2-results > .select2-results__options {
    max-height: inherit;
}

.select2-dropdown {
    border: 0 none;
}

.select2-selection__rendered {
    border-bottom: 2px solid rgb(205, 207, 215);
}

.select2-container--default .select2-selection--single {
    background-color: transparent;
}

.select2-results__option {
    padding: 10px 6px;
}

.select2-search--dropdown {
    padding: 0;
}


/*========================== SELECT2 CUSTOM STYLES END =======================*/
