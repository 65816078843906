/*
Theme Name: Globals - Material and Bootstrap HTML template
Module : Health/Medical
Description: Module level responsive styles are defined in this class
Author: Jyostna
Author URI: http://themeforest.net/user/jyostna
Version: 1.0 
-------------------------------------------- */


/*
TABLE OF CONTENT
-------------------------------------------------
1- RESPONSIVE STYLES
2- MEDIA QUERIES
---------------------------------------------- */


/*========================== RESPONSIVE STYLES =======================*/

.adj-like {
    color: #fff;
    left: 3px;
    position: relative;
    top: 3px;
}

.adj-eye {
    color: #fff;
    left: 0;
    position: relative;
    top: 2px;
}

.filter-content.mixitup-main-box .hover-btn3.btn-h {
    top: 0;
}

.mdi-hardware-keyboard-arrow-right.carousel-mdi-icon2 {
    margin-left: 0;
}

.mdi-hardware-keyboard-arrow-left.carousel-mdi-icon {
    margin-right: 0;
}


/*========================== RESPONSIVE STYLES END =======================*/


/*========================== MEDIA QUERIES =======================*/

@media only screen and (max-width: 768px) {
    .li_1 .meet-icons {
        padding-left: 16%;
    }
    .price_1 .sub-t1 {
        margin-left: 24% !important;
    }
    .price_3 .sub-t1 {
        margin-left: 50% !important;
    }
    .bonnus-box .contact-us:hover {
        margin-bottom: 0;
    }
    .bonnus-box .learn-more:hover {
        margin-bottom: 0;
    }
    .make-div {
        padding-top: 41%;
    }
    .col-xs-12.i_cons {
        padding-left: 111px;
    }
    .app-heading {
        text-align: center;
    }
    .app-sub-heading {
        text-align: center;
    }
    .footer-heading-p {
        margin: 7% 0;
        padding: 0;
    }
    .next.prev-btn > span {
        display: none;
    }
    .prev.prev-btn > span {
        display: none;
    }
    .mega-dropdown-menu > li > ul {
        padding-left: 30px;
    }
    .mega-dropdown-menu .dropdown-header {
        padding-left: 0 !important;
    }
    .top_once {
        margin-top: 7%;
    }
    .div3-color-box1 {
        margin-top: 0;
    }
    .div3-color2-box1 {
        margin-bottom: 20px;
    }
    .div3-color2-box2 {
        margin-bottom: 20px;
    }
    .div3-color-box2 {
        margin-top: 0;
    }
    .div3-color-box4 {
        margin-top: 0;
    }
    .div3-color-box3 {
        margin-top: 0;
    }
    .div3-color2-box4 {
        margin-bottom: 20px;
    }
    .color-row2 {
        margin-top: 2.8%;
    }
    .div2-color-box1 {
        margin-top: 0;
    }
    .div2-color-box2 {
        margin-top: 0;
    }
    .div2-color2-box1 {
        margin-bottom: 20px;
    }
    .contact-doctor {
        margin-top: 8%;
    }
    .meet-logo .meet-icons {
        padding-left: 41%;
    }
    .meet-icons {
        padding-left: 32%;
    }
    .div2-color2-box2 {
        margin-bottom: 20px;
    }
    .div2-color-box3 {
        margin-top: 0;
    }
    .div2-color-box4 {
        margin-top: 0;
    }
    .div4-color1-box1 {
        margin-top: 0;
    }
    .div4-color3-box1 {
        margin-top: 0;
    }
    .div4-color5-box1 {
        margin-top: 0;
    }
    .div4-color6-box1 {
        margin-bottom: 20px;
    }
    .britney-h-box .dropdown-menu {
        left: -80px;
    }
    .div4-color7-box1 {
        margin-top: 0;
    }
    .div4-color2-box1 {
        margin-bottom: 20px;
    }
    .div4-color4-box1 {
        margin-bottom: 20px;
    }
    .color-row3 {
        margin-top: 5.8%;
    }
    .load .mixitup-load-btn {
        width: inherit;
    }
    .filter.fil_ter {
        padding-bottom: 9px;
    }
    .filter.fil_ter:hover {
        padding-bottom: 6px;
    }
    .health_bookan {
        top: -90px;
    }
    .col-md-6.doctor_margin {
        margin-bottom: 5%;
    }
    .mar_gin {
        margin-bottom: 8%;
    }
    .home-slider .mdi-hardware-keyboard-arrow-right.carousel-mdi-icon2-col3-detail1 {
        margin-right: -1px;
    }
    .home-slider .mdi-hardware-keyboard-arrow-left.carousel-mdi-icon-col3-detail1 {
        margin-left: -1px;
    }
    .mdi-hardware-keyboard-arrow-right.adj-oc2 {
        left: 26%;
        top: 150px
    }
    .mdi-hardware-keyboard-arrow-left.adj-oc {
        right: 26%;
        top: 150px
    }
    .dropdown-menu.tooltiptext.drop-down-img {
        left: -90px;
    }
    .filter {
        padding-bottom: 20px;
    }
    .contact-us {
        margin-top: 10%;
        margin-bottom: 10%;
    }
    .learn-more {
        margin-top: 10%;
        margin-bottom: 10%;
    }
    .global-logo {
        display: block;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        top: -13px;
    }
    .expand-nav {
        margin-top: 28px;
        margin-bottom: 10px;
    }
    .navbar .navbar-toggle {
        margin-top: 23px;
    }
    .nav.navbar-nav {
        margin: 0;
        padding: 0;
    }
    .navbar .navbar-nav > .active > a,
    .navbar .navbar-nav > .active > a:hover,
    .navbar .navbar-nav > .active > a:focus {
        padding-top: 21px;
    }
    .navbar .navbar-nav > li > a:hover {
        padding-top: 21px !important;
    }
    .nav.navbar-nav > li {
        padding: 0;
    }
    .navbar .navbar-nav > li > a {
        padding-top: 24px;
    }
    .row1-pad {
        padding-bottom: 0;
    }
    .health-feature-btn {
        margin-top: 0;
        margin-bottom: 50px;
    }
    .tweet > span {
        position: relative;
        left: 11px;
        top: 9px;
    }
    .follow-icons {
        margin-top: 2.4%;
        margin-bottom: 4%;
    }
    .carousel-mdi-icon-col3-detail1 {
        margin-left: -1px;
    }
    .carousel-mdi-icon2-col3-detail1 {
        margin-right: -3px;
        margin-top: 73px;
    }
    .option {
        padding: 6px;
    }
    .error-p {
        font-size: 16px;
    }
    .error-text form {
        width: 100%;
    }
    .oops-h {
        font-size: 42px;
    }
    .sub-t1.sub_1.sub-t2.yearly-sub4 {
        left: 10px;
        position: relative;
    }
    .oops-text {
        font-size: 16px;
    }
    .our-blog-text > br {
        display: none;
    }
    .carousel-mdi-icon {
        margin-top: 170%;
        left: -27%;
    }
    .carousel-mdi-icon2 {
        left: 27%;
        margin-top: 170%;
    }
    .mdi-hardware-keyboard-arrow-left.carousel-mdi-icon-col3-detail1 {
        margin-left: -9px;
        margin-top: 71px;
    }
    .mdi-hardware-keyboard-arrow-right.carousel-mdi-icon2-col3-detail1 {
        margin-right: -9px;
        ;
    }
    .media-body {
        float: left;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
    }
    .prev-btn {
        width: 100px;
    }
    .pagination > li > a,
    .pagination > li > span {
        padding: 6px 3px;
    }
    .next.prev-btn > span {
        position: relative;
        top: 3px;
    }
    .prev.prev-btn > span {
        position: relative;
        top: 2px;
    }
    .icon-set {
        margin-left: 5px !important;
    }
    .mdi-hardware-keyboard-arrow-right.carousel-mdi-icon2 {
        margin-left: -1px;
    }
    .mdi-hardware-keyboard-arrow-left.carousel-mdi-icon {
        margin-right: 0;
    }
    .media-2 {
        margin-top: 18px;
    }
    .filter {
        padding-bottom: 10px;
    }
    .media-left.media-margin {
        float: left;
        margin-top: 18px;
    }
    .padding-ul {
        margin-bottom: 50px;
    }
    .britney-h-box {
        padding: 20px;
    }
    .padd-adj {
        margin-bottom: 40px;
    }
    .sign-mark-1 {
        margin: 0;
        padding: 0;
    }
    .sign-mark-1> ul {
        padding: 0;
        margin: 0;
    }
    .font-paddig {
        padding-top: 40px;
    }
    .row.color-row2 {
        padding: 0 10px;
    }
    .buttons {
        padding: 0 10px;
    }
    .row.color-row3 {
        padding: 0 10px;
    }
    .div4-color8-box1 {
        margin-bottom: 20px;
    }
    .advantages .register-form-box {
        padding: 18% 8% 0;
    }
    .meet-logo .meet-icons {
        padding-left: 33%;
    }
}

@media only screen and (max-width: 425px) {
    .col-md-2.col-xs-6.p-col {
        width: 100%;
    }
    .col-md-2.col-xs-6 {
        width: 100%;
    }
    .col-md-3.col-xs-6 {
        width: 100%;
    }
    .col-md-3.col-xs-6.p-col {
        width: 100%;
    }
    .ul_align {
        margin-top: 30px;
    }
    .icon-size6 {
        left: 0;
        top: -3px;
    }
    .feature-i5 {
        left: 0 !important;
    }
    .icon-size5 {
        left: 0!important;
        top: 0;
    }
    .icon-size2 {
        top: 0;
        left: 0;
    }
    .feature-i3 {
        top: 0;
        left: 0;
    }
    .feature-i4 {
        top: 0;
    }
    .detail-post1-h {
        font-size: 32px;
    }
    .mdi-hardware-keyboard-arrow-right.carousel-mdi-icon2 {
        margin-left: -15px;
    }
    .col-md-5.col-xs-6 {
        width: 100%;
    }
    .col-md-7.col-xs-6 {
        width: 100%;
    }
    .mdi-hardware-keyboard-arrow-right.carousel-mdi-icon2-col3-detail1 {
        margin-right: -11px;
        margin-top: 90px;
    }
    .mdi-hardware-keyboard-arrow-left.carousel-mdi-icon-col3-detail1 {
        margin-left: -11px;
        margin-top: 90px;
    }
    .mdi-hardware-keyboard-arrow-left.carousel-mdi-icon {
        margin-right: -9px;
    }
    .mdi-hardware-keyboard-arrow-right.carousel-mdi-icon2 {
        margin-left: -19px;
    }
    .col-md-10.col-xs-10 {
        width: 100%;
    }
    .col-md-2.col-xs-2 {
        width: 100%;
    }
    .li-align > li {
        padding: 0 2px;
    }
    .border-icon {
        margin-left: 10px;
    }
    .border-box {
        padding: 10px 0;
    }
    .col-xs-2.btn-group-padding {
        padding-left: 10px;
        width: 100%;
    }
    .col-xs-10 {
        width: 100%;
    }
    .center-block {
        max-width: 100%;
    }
    .form-lock-icon {
        max-width: 50%;
        top: 52px;
    }
    .login-form-h {
        font-size: 29px;
    }
    .register-form-box {
        padding: 13% 3% 0;
    }
    .carousel-mdi-icon-col3-detail1 {
        margin-left: -10px;
        margin-top: 73px;
    }
    .carousel-mdi-icon2-col3-detail1 {
        margin-right: -10px;
        margin-top: 73px;
    }
    .appointment::after {
        left: 11%;
        top: -108px;
    }
    .appointment {
        padding: 0 15px;
    }
    .appointment {
        margin: 18% 0 0 !important;
    }
    .advantage-heading {
        font-size: 30px;
    }
    .col-md-6.col-xs-6 {
        width: 100%;
    }
    .bonus-heading {
        font-size: 30px;
    }
    .health-feature-btn {
        padding: 3px 19px 7px 27px !important;
    }
    .expand-nav {
        margin-top: 22px;
    }
    .global-logo {
        top: -5px;
    }
    .tweet > span {
        left: 21px;
    }
    .error-text h2 {
        font-size: 30px;
    }
    .error-p {
        font-size: 16px;
        margin-top: 15px;
    }
    .error-btn {
        padding: 10px 35px;
    }
    .search-input {
        margin-top: 43px;
    }
    .error-text h1 {
        font-size: 100px;
    }
    .oops-h {
        font-size: 36px;
    }
    .meet-heading {
        font-size: 36px;
    }
    .our-statistics-h {
        font-size: 36px;
    }
    .app-icon {
        margin-left: 6px;
    }
    .patient-testimonial-h {
        font-size: 36px;
    }
    .carousel-mdi-icon {
        left: -11%;
    }
    .carousel-mdi-icon2 {
        right: -11%;
    }
    .prev.prev-btn > span {
        display: none;
    }
    .next.prev-btn > span {
        display: none;
    }
    .prev-btn {
        width: 37px;
    }
    .no1 {
        width: 37px;
    }
    .mdi-hardware-keyboard-arrow-left.adj-oc {
        right: 3%;
    }
    .mdi-hardware-keyboard-arrow-right.adj-oc2 {
        left: 3%;
    }
    .home-slider .mdi-hardware-keyboard-arrow-left.carousel-mdi-icon-col3-detail1 {
        margin-right: 0;
    }
    .home-slider .mdi-hardware-keyboard-arrow-right.carousel-mdi-icon2-col3-detail1 {
        margin-right: 0;
    }
    .expand-nav i {
        position: relative;
        top: 6px;
    }
    .advantages .register-form-box {
        padding: 16% 3% 0;
    }
    .twitter .col-xs-1 {
        padding: 0 0 0 5px;
    }
    .list-unstyled.hover_option {
        margin-bottom: 20px;
        margin-left: 30px;
        width: 100%;
    }
    .progress4 .tooltip-inner {
        left: 10px;
        position: relative;
    }
    .price_1 .sub-t1 {
        margin-left: 42% !important;
    }
    .price_3 .sub-t1 {
        margin-left: 40% !important;
    }
    .sub-t1.sub_1 {
        left: 12px;
        position: relative;
    }
    .mdi-hardware-keyboard-arrow-right.adj-oc2 {
        top: 90px
    }
    .mdi-hardware-keyboard-arrow-left.adj-oc {
        top: 90px
    }
    .col-xs-12.i_cons {
        padding-left: 0px;
    }
    .make-div {
        padding-top: 0;
    }
    .dropdown-menu.tooltiptext.drop-down-img {
        left: 0;
    }
    .sub-t1.sub_1.sub-t2.yearly-sub4 {
        left: 18px;
        position: relative;
    }
    .li_1 .meet-icons {
        padding-left: 32%;
    }
    .antonio-admin .dropdown-menu.tooltiptext.drop-down-img {
        left: -100px;
    }
}


/*========================== MEDIA QUERIES END =======================*/
