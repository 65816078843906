/*===============left menu bar section==========*/

body,
html { height: 100%;}
.nav .open > a, 
.nav .open > a:hover, 
.nav .open > a:focus {background-color: transparent;}
#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-left: 220px;
}

#sidebar-wrapper {
    z-index: 1000;
    left: 220px;
    width: 0;
    height: 100%;
    margin-left: -220px;
    overflow-y: auto;
    overflow-x: hidden;
   background: #171a1c !important;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

#wrapper.toggled #sidebar-wrapper {
    width: 300px;
}

#page-content-wrapper {
    width: 100%;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -220px;
}

.sidebar-nav {
    position: relative;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-nav li {
    position: relative; 
    line-height: 20px;
    display: inline-block;
   width: 137%;
}

.sidebar-nav li:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 3px;
    background-color: #1c1c1c;
    -webkit-transition: width .2s ease-in;
      -moz-transition:  width .2s ease-in;
       -ms-transition:  width .2s ease-in;
            transition: width .2s ease-in;

}
.sidebar-nav li:first-child a {
    color: #fff !important;
   
}

.sidebar-nav li:nth-child(3):before {
    background-color: #79aefe;   
}
.sidebar-nav li:nth-child(4):before {
    background-color: #314190;   
}
.sidebar-nav li:nth-child(5):before {
    background-color: #279636;   
}
.sidebar-nav li:nth-child(6):before {
    background-color: #7d5d81;   
}
.sidebar-nav li:nth-child(7):before {
    background-color: #6739b6;   
}
.sidebar-nav li:nth-child(8):before {
    background-color: #9c26b0;   
}
.sidebar-nav li:nth-child(9):before {
    background-color: #e91d62;   
}
.sidebar-nav li:nth-child(10):before {
    background-color: #ff9700;   
}
.sidebar-nav li:hover:before,
.sidebar-nav li.open:hover:before {
    width: 100%;
    -webkit-transition: width .2s ease-in;
      -moz-transition:  width .2s ease-in;
       -ms-transition:  width .2s ease-in;
            transition: width .2s ease-in;

}

.sidebar-nav li a {
    display: block;
    margin-top: 5px;
   margin-bottom: 5px;
    font-size: 16px;
    color: #6b6d76;
    text-decoration: none;
    padding: 10px 15px 10px 30px;  
    font-weight: normal;  
}

.sidebar-nav li a:hover,
.sidebar-nav li a:active,
.sidebar-nav li a:focus,
.sidebar-nav li.open a:hover,
.sidebar-nav li.open a:active,
.sidebar-nav li.open a:focus{
    color: #fff;
    text-decoration: none;
    background-color: transparent;
}

.sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 20px;
    line-height: 44px;
}
.sidebar-nav .dropdown-menu {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: #222;
    box-shadow: none;
}
.hamburger-position{
  position: absolute;
    top: 40.5% !important;
}
.hamburger:hover,
.hamburger:focus,
.hamburger:active {
  outline: none;
}
.hamburger.is-open:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0,0,0);
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-open:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px,0,0);
  -webkit-transition: all .35s ease-in-out;
}
.profile{
	padding: 40px 15px 10px 30px;
}
.profile-name{
	margin-top: 10px;
    font-size: 18px;
}
.profile-name-style{
  margin-top:0 !important;
  margin-bottom:0 !important;
  padding-top:0 !important;
  padding-bottom:0 !important;
}
.user{
	font-size: 15px !important;
	color: #34353c !important;
	margin-top: -15px !important;
	margin-bottom: 20px !important;
}
.profile-box{
	background-image: url("../../images/profile-background.png");
	width: 300px;
}
.plan-types{
	color: #ffffff;
	font-size: 22px;
	margin-top: 40px;
	margin-left: 30px;
	padding-bottom: 25px;
}

.sidebar-nav li:nth-child(3):before {
    background-color: #f04e4e !important;
}
.sidebar-nav li:nth-child(4):before {
    background-color: #ff9700;
}
.sidebar-nav li:nth-child(5):before {
    background-color: #fecc17;
}
.sidebar-nav li:nth-child(6):before {
    background-color: #00bff3;
}
.userful-p{
	color: #6b6d76;
	font-size: 14px;
	margin-left: 7px;
	margin-bottom: 19px;
  font-weight: normal;
}
.leftmenu-a{
  color: #6b6d76;
  font-weight: normal;
}
.user-h:hover .leftmenu-a{
  color: #00bff3;
  text-decoration: none;
}
.user-h:hover .userful-p{
	color: #00bff3;
}
.user-h:hover .userful-i{
	color: #00bff3;
}
 
.userful-i{
	color: #6b6d76;
	font-size: 16px;
	margin-left: 30px;
	position: relative;
    right: 9px;
    top: 2px;
}
.plan-type-input{
	margin-left: 30px;
	opacity: 1;
  font-size: 16px;
  background-image: linear-gradient(#00bff3, #00bff3)!important;
  border-bottom: 2px solid #414549;
  color: #ffffff;
}
/*.drop-search{
	color: #ffffff;
	font-size: 22px;
	margin-top: 40px;
	margin-left: 30px;
	padding-bottom: 10px;
}*/

.drop-search{
    color: #ffffff;
    font-size: 22px;
    margin-top: -10px;
    margin-left: 30px;
    padding-bottom: 10px;
}



.back-icon{
	color: #ffffff;
	margin: 30px;
}
.back-icon:hover {
    color: #00BFF3;
    cursor: pointer;
    margin: 30px;
}
/*=====left menu bar closed=======*/




